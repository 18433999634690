@import '../base/color';

.table {
  width: 100%;
  border:none;
  border-collapse: collapse;
  margin-bottom: 0px;

  &-stripped {
    thead {
      th { 
        background: transparent; 
        color: $fontSecondary;
        font-weight: 400;
        font-size: 0.875rem;
      }
    }

    tbody { 
      tr {
        &:first-child td:first-child {
          border-top-left-radius: 10px;
        }

        &:first-child td:last-child {
          border-top-right-radius: 10px;
        }

        &:last-child td:first-child {
          border-bottom-left-radius: 12px;
        }

        &:last-child td:last-child {
          border-bottom-right-radius: 12px;
        }
      }

      td { 
        background: $background !important; 
        padding: 11px;
      }
    }
  }

  tr {
    padding: 15px 0;

    &.disabled-row {
      background-color: #dcdcdc;

      .disabled-item {
        pointer-events: none !important;
      }
    }

    th, td { 
      vertical-align: middle;
      background: transparent; 
      color: $fontSecondary;
      font-weight: 400;
      font-size: 0.875rem;
    }
  }

  th, td {
    font-size: 0.75rem;
    border: none !important;
  }

  th {
    font-weight: 700;
    text-transform: uppercase;
  }

  td { 
    font-weight: 400; 

    &:last-child .action { cursor: pointer; }
    
    .action { 
      font-size: 1rem;
      
      &:not(&:last-child) { margin-right: 5px; }
    }
  }

  tbody {
   border: none !important;
   tr { border-bottom: 7px solid $white; }
  }

  &-responsive {
    width: 100%;
  }

  tr.total {
    background-color: $default !important;
    
    td { 
      color: $white !important; 
      font-weight: 500 !important; 
      font-size: 1rem;

      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }

  .table-tag {
    background-color: $white;
    padding: 6px 12px;
    color: $primary;
    border-radius: 10px;
  }
}


// MUI TABLE
.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows { margin: 0; }