@import '../../../../styles/base/color';
@import '../../../../styles/mixins/mixins';

.confirm {
  width: 360px !important;
  height: auto !important;
  background: #fff;
  transition: .6s;
  z-index: 100;
  position: absolute;
  top: -280px;
  left: calc(50% - 180px);
  border-radius: 8px;
}

.confirm.show {
  top: calc(50% - 140px);
  padding-right: 0 !important;
}

.confirm-content {
  @include flexCol(center, center);
  gap: 1px;
  height: 70%;
  padding: 32px 20px 20px;

  &__icon {
    color: $warning;
    font-size: 55px;
  }

  &__text {
    font-size: 21px !important;
    color: $primary !important;
    text-align: center !important;
    line-height: 26px !important;
    padding: 20px 0 3px;
    max-width: 260px;
  }
}

.confirm-btns {
  @include flexRow(center, center);
  gap: 20px;
  padding: 5px 0 25px;

  &__confirm {
    width: 92px;
    color: $white;
    background-color: $primary;
    position: relative;
    font-weight: 600 !important;
  }
  
}

.confirm-btns button {
  font-weight: 500;
  cursor: pointer;
  padding: 8px 12px !important;
  border-radius: 8px;
  border: none;
  border: 1px solid $primary;
}

.confirm-btns button:active {
  transform: scale(0.98);
}

.overlay {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .5);
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.confirm.show + .overlay {
  display: block;
}

@media screen and (max-width: 450px) {
  .confirm {
    width: 300px;
    left: calc(50% - 150px);
  }
}
