.floatingWhatsapp {
    width: 100%;
    text-align: left;
    position: relative;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue",
      Helvetica, Arial, sans-serif;
  }
  
  .whatsappButton {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    cursor: pointer;
    background-color: #25d366;
    border-radius: 50%;
    user-select: none;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 15%);
    z-index: 9998;
  }
  
  .whatsappButton:after {
    content: "";
    border-color: inherit;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    box-shadow: 0 0 0 0 rgba(37, 211, 101, 0);
    animation: _pulse 1.2s infinite;
  }
  
  .whatsappButton svg {
    fill: #fff;
    padding: 0.5rem;
    width: 55px;
    height: 55px;
  }
  
  .whatsappChatBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 5%;
    overflow: hidden;
    touch-action: auto;
    position: fixed;
    // background-color: #fff;
    bottom: 7rem;
    right: 4rem;
    width: 375px;
    opacity: 0;
    transition: height 0.2s ease-out;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 20%);
    z-index: 9999;
  }
  
  @media (max-width: 575px) {
    .whatsappChatBox {
      right: 0;
      left: 0;
      max-width: 90%;
      margin: auto;
    }
  }
  
  .whatsappChatBox.open {
    animation: _bounceIn 0.4s both;
  }
  
  .whatsappChatBox.close {
    animation: _bounceOut 0.4s both;
  }
  
  .chatHeader {
    background-color: #075e54;
    display: -ms-grid;
    display: grid;
    align-items: center;
    padding: 0.25rem;
    grid-template-columns: 20% 60% 1fr;
    gap: 1%;
  }
  
  .chatHeader .avatar {
    padding: 0.25rem;
    position: relative;
  }
  
  .chatHeader .avatar img {
    vertical-align: middle;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    border: 1px solid rgba(255, 255, 255, 0.473);
  }
  
  .chatHeader .avatar:after {
    content: "";
    width: 10px;
    height: 10px;
    background: #4fbe86;
    border: 1px solid #fff;
    position: absolute;
    border-radius: 50%;
    bottom: 5px;
    right: 8px;
  }
  
  .chatHeader .avatar svg {
    fill: #fff;
    vertical-align: middle;
  }
  
  .chatHeader .status {
    color: #fff;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
  }
  
  .chatHeader .statusTitle {
    font-size: 1rem;
    font-weight: 700;
  }
  
  .chatHeader .statusSubtitle {
    font-size: 0.8rem;
    color: #f0f0f0;
  }
  
  .chatHeader .close {
    padding: 1rem;
    cursor: pointer;
    text-align: center;
  }
  
  .chatHeader .close svg {
    fill: #fff;
    width: 24px;
    height: 24px;
  }
  
  .chatBody {
    padding: 20px;
    background-size: cover;
    background-repeat: repeat;
    max-height: 382px;
    background-color: #eae6df;
    height: 100%;
  }
  
  .chatBubble {
    background-color: #e6f8f1;
    padding: 16px 28px;
    border-radius: 20px;
    border-bottom-left-radius: 2px;
    display: inline-block;
  }
  
  .typing {
    align-items: center;
    display: flex;
    height: 17px;
  }
  
  .typing .dot {
    animation: typingAnimation 1.8s infinite ease-in-out;
    background-color: rgba(20, 105, 69, 0.7);
    border-radius: 50%;
    height: 7px;
    margin-right: 4px;
    vertical-align: middle;
    width: 7px;
    display: inline-block;
  }
  
  .typing .dot:nth-child(1) {
    animation-delay: 200ms;
  }
  
  .typing .dot:nth-child(2) {
    animation-delay: 300ms;
  }
  
  .typing .dot:nth-child(3) {
    animation-delay: 400ms;
  }
  
  .typing .dot:last-child {
    margin-right: 0;
  }
  
  .chatBody .message {
    padding: 7px 14px 6px;
    background-color: #fff;
    border-radius: 0px 8px 8px;
    position: relative;
    max-width: calc(100% - 120px);
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 13%) 0px 1px 0.5px;
  }
  
  .chatBody .accountName {
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
    color: rgba(17, 17, 17, 50%);
  }
  
  .chatBody .messageBody {
    font-size: 14px;
    line-height: 19px;
    margin-top: 4px;
    color: #111111;
    white-space: pre-wrap;
    text-align: start;
  }
  
  .chatBody .message .triangle {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 20px 20px 0;
    border-color: transparent #fff transparent transparent;
    position: absolute;
    left: -10px;
    top: 0;
  }
  
  .chatBody .messageTime {
    display: flex;
    margin-top: 4px;
    font-size: 12px;
    line-height: 16px;
    color: rgba(17, 17, 17, 50%);
    justify-content: flex-end;
  }
  
  .chatFooter {
    padding: 0.25rem;
    background-color: #f0f0f0;
  }
  
  .chatFooter form {
    display: -ms-grid;
    display: grid;
    align-items: center;
    grid-template-columns: 85% 15%;
    padding: 0.25rem;
  }
  
  .chatFooter form .input {
    border-radius: 20px;
    padding: 0 10px;
    border: none;
    background-color: #fefefe;
    min-height: 45px;
    transition: box-shadow 0.2s ease-in-out;
  }
  
  .chatFooter form .input:focus {
    border-color: #075e54;
    outline: 0;
    box-shadow: 0 0 0 1px rgba(7, 94, 84, 50%);
  }
  
  .chatFooter form .input::-webkit-input-placeholder {
    color: #949494;
  }
  
  .chatFooter form .input::-moz-placeholder {
    color: #949494;
  }
  
  .chatFooter form .input:-ms-input-placeholder {
    color: #949494;
  }
  
  .chatFooter form .input::-ms-input-placeholder {
    color: #949494;
  }
  
  .chatFooter form .input::placeholder {
    color: #949494;
  }
  
  .chatFooter form .buttonSend {
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }
  
  .chatFooter form .buttonSend svg {
    vertical-align: middle;
    fill: #919191;
  }
  
  .chatFooter form .buttonSend:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  
  /* dark mode */
  .dark .chatBody {
    background-color: #0d1418;
  }
  
  .dark .chatBody .message {
    background-color: #056162;
    box-shadow: rgba(0, 0, 0, 70%) 0px 1px 0.5px;
  }
  
  .dark .chatBody .accountName {
    color: rgba(255, 255, 255, 50%);
  }
  
  .dark .chatBody .messageBody {
    color: rgba(241, 241, 242, 90%);
  }
  
  .dark .chatBody .message .triangle {
    border-color: transparent #056162 transparent transparent;
  }
  
  .dark .chatBody .messageTime {
    color: rgba(255, 255, 255, 50%);
  }
  
  .dark .chatFooter {
    background-color: #1e2428;
  }
  
  .dark .chatFooter form .input {
    background-color: #33383b;
    color: #f1f1f2;
  }
  
  .dark .chatFooter form .input:focus {
    border-color: #0da896;
    outline: 0;
    box-shadow: 0 0 0 1px rgba(13, 168, 150, 50%);
  }
  
  .dark .chatFooter form .buttonSend svg {
    fill: rgb(104, 104, 104);
  }
  
  .dark .chatBubble {
    background-color: #32373a;
  }
  
  .dark .typing .dot {
    background-color: rgba(46, 148, 106, 0.7);
  }
  
  /* keyframes */
  @keyframes _pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(37, 211, 101, 0.75);
    }
    100% {
      box-shadow: 0 0 0 15px rgba(37, 211, 101, 0);
    }
  }
  
  @keyframes _bounceIn {
    0% {
      opacity: 0;
      transform: scale(0, 0);
      transform-origin: bottom right;
    }
  
    50% {
      transform: scale(1.03, 1.03);
      transform-origin: bottom right;
    }
  
    100% {
      opacity: 1;
      transform: scale(1, 1);
      transform-origin: bottom right;
    }
  }
  
  @keyframes _bounceOut {
    0% {
      opacity: 1;
      height: scale(1, 1);
      transform-origin: bottom right;
    }
    100% {
      opacity: 0;
      transform: scale(0, 0);
      transform-origin: bottom right;
    }
  }
  
  @keyframes typingAnimation {
    0% {
      transform: translateY(0px);
      background-color: rgba(20, 105, 69, 0.7);
    }
    28% {
      transform: translateY(-7px);
      background-color: rgba(20, 105, 69, 0.4);
    }
    44% {
      transform: translateY(0px);
      background-color: rgba(20, 105, 69, 0.2);
    }
  }
  
  .notificationIndicator {
    display: flex;
    flex-flow: row wrap;
    place-content: center;
    align-items: center;
    position: absolute;
    box-sizing: border-box;
    font-family: inherit;
    font-weight: 500;
    font-size: 0.75rem;
    min-width: 20px;
    line-height: 1;
    padding: 0px 6px;
    height: 20px;
    border-radius: 10px;
    z-index: 1;
    transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgb(235, 0, 20);
    color: rgb(255, 255, 255);
    top: 0;
    right: 10px;
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;
  }
  