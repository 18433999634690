@import '../../../../../../../../styles/base/color';
@import '../../../../../../../../styles/base/breakpoints';
@import '../../../../../../../../styles/mixins/mixins';

.direccion-card {
    @include basicsCardDireccion();
    position: relative;
    @include flexRow(center, space-between);
    
    @include mdMax {
        gap: 15px;
    }

    @include lg {
        gap: 15px;
    }

    &__text-container {
        width: 80%;
    }

    &.selected {
        background-color: $primaryLight;
    }

    &.out-of-range {
        opacity: 0.7;
        cursor: not-allowed;

        input {
            cursor: not-allowed;
        }
        
        &>div {
            width: 100%;
            padding: 0;
            cursor: not-allowed;

            p.direccion-card__text-out-of-range {
                font-size: 9px !important;
            }
            
            @include lgMax {
                max-width: none !important;
            }
        }
    }

    &__cif-ue {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;
    }

    &__text-out-of-range {
        color: $danger !important;
        text-align: end;

        @include sm {
            position: absolute;
            right: 10px;
            bottom: 12px;
        }
        
    }

    input[type=radio] {
        // display: none;
        cursor: pointer;
        accent-color: $primary;

        @include md {
            margin-left: -37px;
            position: absolute;
            display: flex;
        }
        
        @include lg {
            margin-left: 0;
            position: relative;
        }
        
        @include xl {
            margin-left: -37px;
            position: absolute;
        }
    }

    &__no-cursor-pointer {
        cursor: default !important;
    }

    &__editable {
        @include btnEditDelete();
        padding: 0 !important;

        &:active {
            -webkit-transform: scale(0.98);
            transform: scale(0.98);
        }
    }

    &__noneditable {
        visibility: hidden;
    }

}
