@import '../../../../../../../../../../styles/base/color';

.places-search {
    width: 100%;
    position: relative;

    &-container {
        position: absolute;
        border: 1px solid #d4d4d4;
        border-bottom: none;
        border-top: none;
        z-index: 99;
        top: 100%;
        left: 0;
        right: 0;
    }

    .suggestion-item {
        padding: 10px;
        cursor: pointer;
        background-color: #fff;
        border-bottom: 1px solid #d4d4d4;
        color: $primary !important;

        &:hover {
            background-color: #e9e9e9;
        }

        &-active {
            padding: 10px;
            cursor: pointer;
            background-color: $primary !important;
            color: #fff !important;
        }
    }
}