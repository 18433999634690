@import '../../../../../../../../../../styles/mixins/mixins';
@import '../../../../../../../../../../styles/base/color';
@import '../../../../../../../../../../styles/base/breakpoints';

.transferencia-form {
  
  form {
    padding-top: 55px;
    justify-content: center;

    @include lg {
      padding-left: 200px;
      padding-right: 200px;
    }

    @include custom(1400px) {
      padding-left: 300px;
      padding-right: 300px;
    }
  }

  &__return {
    padding: 20px 0 0;
    width: 205px;

        p {
            @include flexRow(center, center);
            gap: 6px;
            background-color: $white;
            @include primaryText(13px);
            border: 1px solid $primary;
            border-radius: 6px;
            padding: 6px 9px;
            font-weight: 500;
            cursor: pointer;
        }
  }

  &__btn {
    width: 200px;
    height: 36px;
    border-radius: 6px;
    background-color: $primary;
    color: $white;
    font-size: 13px;
    cursor: pointer;
    margin-top: 16px;
    position: relative;

    &:disabled {
      opacity: 0.8;
      cursor: default;
    }
  }
}

