@import '../../../styles/base/color';
@import '../../../styles/mixins/mixins';
@import '../../../styles/base/breakpoints';

.condiciones-generales {

  &__container {

    @include md {
      max-width: 720px;
      margin: auto;
    }

    @include lg {
      padding: 0 32px 32px !important;
      max-width: 1180px;
    }

    @include custom(1400px) {
      padding: 0 60px 32px !important;
    }

    @include custom(1500px) {
      margin: auto;
      padding: 0 10px 32px !important;
      max-width: 1320px;
    }

    &--index {
    width: 100%;
    display: flex;
    
      @include md {
        padding-left: 1.5rem !important;
      }

      @include lg {
        padding-left: 0 !important;
      }

      >ul {
        padding: 0 !important;
        padding-right: 20px !important;
        line-height: 22px;
        font-size: 13px;
        color: $primary;
        font-weight: 500;

        @include lg {
          padding-top: 28px !important;
        }

        li {
          cursor: pointer;

          &:hover {
            font-weight: 600;
          }
        }
      }

      &--subindex {
        padding-top: 0 !important;

        li {
          list-style-type: circle !important;
          margin-left: 5px;
        }
      }
    }

    &--info {
      @include md {
        padding: 1.5rem;
      }
    }
  }
}