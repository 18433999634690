@import '../../../../../styles/base/color';
@import '../../../../../styles/base/breakpoints';

.change-language {     
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem 0;
  border: none;
  color: $primary;
  
  @include md {
    margin-left: 36px;
  }
  
  &__select {
      padding: 0 !important;
      color: $primary;
      cursor: pointer;
      border: none !important;
      outline: none !important;
  }

}