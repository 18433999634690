@import '../../styles/base/color';

.seguimiento { 
    .direccion {
        small { 
            font-size: 0.875rem;
            text-transform: uppercase;
            margin-bottom: 5px;
        }
    }

    .text-green { color: #00986C !important; }

    &-envio {
        p { margin: 0; }

        &-item {
            background-color: $gray;
            padding: 20px;
            border-radius: 8px;
        }
    }
}