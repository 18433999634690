@import '../base/color';
@import '../base/typography';

$theme-colors: (
  "primary": $primary,
  "default": $default,
  "danger": $danger,
  "warning": $warning,
  "success": $primary,
  "info": $info
);

:root {
  --tab-active: #eaf3ff;
  --table-striped-active: #eaf3ff
}

.dashboard-container {
  height: 100%;
  display: flex;

  .content {
    max-height: 100vh;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    padding: 0 1.5rem 1.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &-table {
      margin-top: 33px;
    }

    .loading {
      span {
        margin-left: 1rem;
        font-weight: 500;
        font-size: 1.25rem;
      }
    }
  }
}

.pill { 
  padding: 5px 10px;
  border-radius: 16px;
  font-size: 0.75rem;
  color: $fontPrimary;

  &.warning { background-color: $warning; }

  &.success { background-color: $primary; }

  &.green { background-color: $green; }

  &.primaryLight {background-color: $primaryLight}
}

@import "~bootstrap/scss/bootstrap";
@import 'react-toastify/dist/ReactToastify.css';