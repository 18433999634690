@import '../../../../../../../../../styles/base/color';
@import '../../../../../../../../../styles/base/breakpoints';
@import '../../../../../../../../../styles/base/typography';
@import '../../../../../../../../../styles/mixins/mixins';


#input-paises, .select-horario {
  width: 100%;
  margin: 0 !important;
  background-color: $background !important;
  border-radius: 8px !important;
  height: 40px !important;
  padding: 0 !important;
}

#input-paises > div, .select-horario > div {
  height: 100% !important;
  font-size: 0.875rem !important;
  font-family: $rubik !important;
  font-weight: 400 !important;
  color: $fontPrimary !important;
}
