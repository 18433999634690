@import '../base/color';

.navbar {
    padding-top: 25px;
    padding-bottom: 25px;

    @media screen and (max-width: 768px) {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .navbar-brand .logo { height: 20px; }

    .navbar-toggler {
        border: 2px solid $primary;
    }

    .nav-item {
        color: $primary !important;
        font-size: 1rem;
        margin: 0 25px;

        @media screen and (max-width: 768px) {
            padding: 15px;
        }
    }
}