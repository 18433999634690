@import '../base/color';

.form-group label, label {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
}

textarea { background-color: $white !important; }

/* INPUT */
fieldset.MuiOutlinedInput-notchedOutline {
  border: 1px solid $primary !important;
}

.css-hdw1oc{ display: none !important; }

.MuiAutocomplete-endAdornment { top: unset !important; }

.MuiOutlinedInput-root .MuiAutocomplete-input { border: none !important; }


input[type=text], 
input[type=search], 
input[type=tel], 
input[type=password], 
input[type=email], 
input[type=number], 
input[type=date],
.MuiInput-root,
.form-control, 
.form-control[readonly] {
  min-width: 95px !important;
  border: 1px solid $primary;
  font-size: 0.875rem !important;
  font-weight: 300 !important;
  padding: 12.5px 30px 12.5px 15px !important;
  border-radius: 8px !important;
  background-color: $white;
  color: $primary !important;

  @media screen and (max-width: 768px) {
    & { font-size: 0.875rem !important; }
  }

  &::placeholder { color: $primaryLight !important; }

  &:focus {
    border-color: $primary !important;
    box-shadow: none !important;
  }

  &:disabled {
    opacity: 0.7;
    cursor: default;
  }
}

.MuiAutocomplete-inputRoot {
  min-width: 95px !important;
  background-color: $white;
  border-radius: 8px !important;

  input {
    border: 1px solid $primary;
    font-size: 0.875rem !important;
    font-weight: 300 !important;
    padding: 7px 30px 7px 10px !important;
    color: $primary !important;
  }

  @media screen and (max-width: 768px) {
    & { font-size: 0.875rem !important; }
  }

  &::placeholder { color: $primaryLight !important; }

  &:focus {
    border-color: $primary !important;
    box-shadow: none !important;
  }
}

.css-a88p61-MuiInputBase-root-MuiInput-root:before,
.css-a88p61-MuiInputBase-root-MuiInput-root:after,
.css-ercmji:before,
.css-ercmji:after,
.css-ercmji:hover:not(.Mui-disabled)::before { 
  border: none !important; 
  transition: none !important;
}

.css-1ar2bnf-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  padding: 0.5px 32px 0.5px 0 !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  padding: 6px 16px;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  cursor: pointer;
  vertical-align: middle;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

input[type=number] { padding-right: 0 !important; }

input.form-control-sm {
  height: 32px !important;
  padding: 8px 8px 6px;
}

.date-picker {
  position: relative;
  display: flex;
  align-items: center;

  & > div { 
    position: absolute;
    right: 12px;
    bottom: 20px;
  }
}

select, textarea {
  width: 100%;
  border: 1px solid $primary !important;
  font-size: 0.875rem !important;
  font-weight: 300 !important;
  padding: 12.5px 20px 12.5px 15px !important;
  border-radius: 8px !important;
  background-color: $white;
}

select {
  position: relative;
}

select::after 
{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

select.open:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.form-check input[type="checkbox"]:not(:checked) + label,
.form-check input[type="checkbox"]:checked + label {
  position: relative;
  cursor: pointer;
  color: $fontQuaternary;
}

/* CHECKBOX UNCHECKED ASPECT */
.form-check input[type="checkbox"]:not(:checked) + label:before,
.form-check input[type="checkbox"]:checked + label:before, 
.form-check .custom-control-label::before,
.form-check .custom-control-input:checked ~ .form-check .custom-control-label::before {

  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border: 2px solid $primary;
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: none !important;
}

.form-check .custom-control-input:focus:not(:checked) ~ .form-check .custom-control-label::before {
  border-color: $primary !important;
}

/* CHECKBOX CHECKED ASPECT */
.form-check input[type="checkbox"]:checked + label:after,
.form-check .custom-control-label::after {

  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  font-size: 1.3em;
  line-height: 0.8;
  transition: all .2s;
  box-shadow: none !important;
}


.form-check .custom-checkbox .custom-control-input:checked ~ .form-check .custom-control-label::after, 
.form-check input[type=checkbox]:checked + label:after, .form-check .custom-control-label::after { 
  background: $primary;
}

/* DISABLED CHECKBOX checkbox */

.form-check input[type="checkbox"]:disabled:not(:checked) + label:before,
.form-check input[type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: $primaryLight;
}
.form-check input[type="checkbox"]:disabled:checked + label:after {
  background-color: $primaryLight;
}
.form-check input[type="checkbox"]:disabled + label {
  color: $primaryLight;
}


.MuiStepLabel-root.MuiStepLabel-horizontal.Mui-disabled,
.MuiStepLabel-iconContainer.Mui-disabled,
.MuiStepLabel-label.Mui-disabled {
  background-color: transparent !important;
}