@import '../../../../../../../../styles/base/color';
@import '../../../../../../../../styles/base/breakpoints';

.resume {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;

  @include lg {
    padding-top: 20px;
  }

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    p {
      cursor: pointer;
      width: 30px;
      position: relative;

      svg {
        font-size: 16px;
        color: $primary;
        &:hover {
          color: $checkBoxCheked;
        }
      }
    }
  }

  &__desk-table {
    border-spacing: 0px 8px !important;
    border-collapse: separate !important;
    padding-top: 28px;
  }

  &__titles {
      width: 100%;
      display: grid;
      grid-template-columns: 4.5fr 1.1fr 1.3fr;
      font-size: 11px !important;
      margin-bottom: -5px;
      padding-top: 32px;
      
      @media screen and (max-width: 768px) {
        font-size: 9px !important;
      }
      
      @include lg {
        padding-top: 22px;
      }

      &--center {
        text-align: center;
      }
  }

  &__titles-desk {
    font-size: 12px !important;
    padding: 0 !important;
  }

  &__labels-desk {
    font-size: 13px !important;
    color: $primary !important;
    border-radius: 30px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  &__nombre-pieza-desk {
    color: $primary !important;
    // font-size: 14px !important;
    font-weight: 600 !important;
  }

  &__img-desk{
    width: 125px;
    height: auto;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    padding-right: 15px;

    &--span {
      width: 105px;
      height: 105px;
      overflow: hidden;
      background-color: $white;
      border-radius: 6px;

      &--img {
          width: 100%;
          height: 100%;
          object-fit: contain;
      }
    }
  }

  &__each-desk-row {

    &--all-row{
      border-bottom: none !important;
    }
    &--left {
      border-top-left-radius: 6px !important;
      border-bottom-left-radius: 6px !important;
    }
    
    &--right {
      border-top-right-radius: 6px !important;
      border-bottom-right-radius: 6px !important;
    }
  }

  &__each-card-mb {
      display: grid;
      grid-template-columns: 0.8fr 3.7fr 1.1fr 1.3fr;
      width: 100%;
      background-color: $background;
      border-radius: 6px;
      align-items: center;

      &--foto {
         padding: 7px;
         width: 100%;
         height: 100%;
         display: flex;
         align-items: center;
         justify-content: center;

         &--span {
          width: 65px;
          height: 65px;
          overflow: hidden;
          background-color: $white;
          border-radius: 6px;

          @media screen and (max-width: 768px) {
            width: 48px;
            height: 48px;
          }

          &--img {
              width: 100%;
              height: 100%;
              object-fit: contain;
          }
          
         }
      }

      &--producto {
        padding: 7px;
        &--nombre-pieza {
          font-size: 14px !important;
          line-height: 14px;
          font-weight: 600 !important;
          color: $primary !important;

          @media screen and (max-width: 768px) {
            font-size: 13px !important;
          }
        }
        
        &--garantia {
          font-size: 14px !important;
          padding-top: 3px;
          @media screen and (max-width: 768px) {
            font-size: 12px !important;
          }
        }
      }
      
      &--unidades, &--precio {
        font-size: 14px !important;
        text-align: center;
        font-weight: 600 !important;
        @media screen and (max-width: 768px) {
          font-size: 12px !important;
        }
      }

      &--extra-info {
        font-style: italic;
        font-size: 14px;
        border-top: 1px solid $boxShadow;
        grid-column: span 4;
      }
  }
}

.img-default {
  object-fit: cover !important;
}